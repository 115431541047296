import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "home",
  },
  // {
  //   id: "sample",
  //   title: "Sample",
  //   translate: "MENU.SAMPLE",
  //   type: "item",
  //   icon: "file",
  //   url: "sample",
  // },
  {
    id: "post",
    title: "Post Management",
    translate: "MENU.POST.POST_MANAGEMENT",
    type: "collapsible",
    icon: "package",
    children: [
      {
        id: "post",
        title: "Post",
        translate: "MENU.POST.POST_LIST",
        type: "item",
        icon: "circle",
        url: "pages/post-management/post",
      },
      {
        id: "post-tag",
        title: "Tag",
        translate: "MENU.POST.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/post-management/post-tag",
      },
    ],
  },
  {
    id: "destination",
    title: "Destination Management",
    translate: "MENU.DESTINATION.DESTINATION_MANAGEMENT",
    type: "collapsible",
    icon: "map",
    children: [
      {
        id: "destination",
        title: "Destination",
        translate: "MENU.DESTINATION.DESTINATION_LIST",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/destination",
      },
      {
        id: "destination-category",
        title: "Place To Go",
        translate: "MENU.DESTINATION.PLACE_TO_GO",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/place-to-go",
      },
      {
        id: "destination-category",
        title: "Category",
        translate: "MENU.DESTINATION.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/destination-management/destination-category",
      },
    ],
  },
  {
    id: "course",
    title: "Course Management",
    translate: "MENU.COURSE.COURSE_MANAGEMENT",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "course",
        title: "Course",
        translate: "MENU.COURSE.COURSE_LIST",
        type: "item",
        icon: "circle",
        url: "pages/course-management/course",
      },
      {
        id: "course-category",
        title: "Category",
        translate: "MENU.COURSE.CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/course-management/course-category",
      },
    ],
  },
  {
    id: "youth-ambassador",
    title: "Youth Ambassador Management",
    translate: "MENU.YOUTH_AMBASSADOR.YOUTH_AMBASSADOR_MANAGEMENT",
    type: "collapsible",
    icon: "award",
    children: [
      {
        id: "youth-ambassador-activity",
        title: "Activity",
        translate: "MENU.YOUTH_AMBASSADOR.ACTIVITY",
        type: "item",
        icon: "circle",
        url: "pages/youth-ambassador-management/activity",
      },
      {
        id: "youth-ambassador-program",
        title: "Program",
        translate: "MENU.YOUTH_AMBASSADOR.PROGRAM",
        type: "item",
        icon: "circle",
        url: "pages/youth-ambassador-management/program",
      },
    ],
  },
  {
    id: "event",
    title: "Event Management",
    translate: "MENU.EVENT.EVENT_MANAGEMENT",
    type: "item",
    icon: "cast",
    url: "pages/event-management/event",
  },
  {
    id: "admin",
    title: "Administrator",
    translate: "MENU.ADMINISTRATOR.ADMIN_ADMINISTRATOR",
    type: "collapsible",
    icon: "shield",
    children: [
      {
        id: "role",
        title: "Roles",
        translate: "MENU.ADMINISTRATOR.ROLE",
        type: "item",
        icon: "circle",
        url: "pages/administrator-management/role",
      },
      {
        id: "admin",
        title: "Admin",
        translate: "MENU.ADMINISTRATOR.ADMIN",
        type: "item",
        icon: "circle",
        url: "pages/administrator-management/admin",
      },
    ],
  },
  {
    id: "e-library",
    title: "E-Library Management",
    translate: "MENU.E_LIBRARY.E_LIBRARY_MANAGEMENT",
    type: "collapsible",
    icon: "book",
    children: [
      {
        id: "e-library",
        title: "E-Library",
        translate: "MENU.E_LIBRARY.E_LIBRARY",
        type: "item",
        icon: "circle",
        url: "pages/e-library-management/e-library",
      },
      {
        id: "e-library-category",
        title: "Category",
        translate: "MENU.E_LIBRARY.E_LIBRARY_CATEGORY",
        type: "item",
        icon: "circle",
        url: "pages/e-library-management/e-library-category",
      },
    ],
  },
  {
    id: "setting",
    title: "Settings",
    translate: "MENU.SETTING.SETTING",
    type: "collapsible",
    icon: "settings",
    children: [
      {
        id: "about",
        title: "About",
        translate: "MENU.SETTING.ABOUT",
        type: "item",
        icon: "circle",
        url: "pages/settings/about",
      },
      {
        id: "about",
        title: "What We Do",
        translate: "MENU.SETTING.WHAT_WE_DO",
        type: "item",
        icon: "circle",
        url: "pages/settings/what-we-do",
      },
    ],
  },
];

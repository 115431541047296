export const locale = {
  lang: "en",
  data: {
    MENU: {
      HOME: "Home",
      POST: {
        POST_MANAGEMENT: "Post Management",
        POST_LIST: "Post",
        CATEGORY: "Category",
      },
      DESTINATION: {
        DESTINATION_MANAGEMENT: "Destination Management",
        DESTINATION_LIST: "Destination",
        CATEGORY: "Category",
        PLACE_TO_GO: "Place To Go",
      },
      COURSE: {
        COURSE_MANAGEMENT: "Course Management",
        COURSE_LIST: "Course",
        CATEGORY: "Category",
      },
      YOUTH_AMBASSADOR: {
        YOUTH_AMBASSADOR_MANAGEMENT: "Youth Ambassador Management",
        ACTIVITY: "Activity",
        PROGRAM: "Program",
      },
      EVENT: {
        EVENT_MANAGEMENT: "Event Management",
        EVENT_LIST: "Event",
      },
      SETTING: {
        SETTING: "Setting",
        ABOUT: "About",
        WHAT_WE_DO: "What We Do",
      },
      CUSTOMER: {
        CUSTOMER: "Customer",
        WALLET: "Wallet",
        POINT: "Point",
        DRIVER: "Driver",
      },
      E_LIBRARY: {
        E_LIBRARY_MANAGEMENT: "E-Library Management",
        E_LIBRARY: "E-Library",
        E_LIBRARY_CATEGORY: "E-Library Category",
      },
      ADMINISTRATOR: {
        ADMIN_ADMINISTRATOR: "Administrator",
        ADMIN: "Admin",
        ROLE: "Role",
      },
    },
  },
};
